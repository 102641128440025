import React from "react"
import paragraphs from "lines-to-paragraphs"

const Heading = ({ className, children }) => {
  if (typeof children === "string") {
    return (
      <div
        dangerouslySetInnerHTML={{ __html: paragraphs(children) }}
        className={`primary-heading ${className}`}
      />
    )
  }

  return <h2 className={`primary-heading ${className}`}>{children}</h2>
}

Heading.defaultProps = {
  className: "",
}

export default Heading
