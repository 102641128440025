import React from "react"
import Swiper from "react-id-swiper"
import SEO from "components/seo"
import Img, { Photo } from "components/Img"
import VideoWrapper from "components/VideoWrapper"
import Heading from "components/Heading"
import { motion } from "framer-motion"
import { motionVariants, heroTextVariants } from "utils/motion.utils"

const ViewAward = ({pageData}) => {
  const { data } = pageData;

  const params = {
    containerClass: "swiper-container swiper-caption-container awards-swiper",
    slidesPerView: 1,
    spaceBetween: 15,
    loop: false,
    navigation: {
      nextEl: ".swiper-button-next.is-white",
      prevEl: ".swiper-button-prev.is-white",
    },
  }

  return (
    <React.Fragment>
      <SEO title={data.page} />
      <div className="max-container real-hero wrapper--award--hero">
        <motion.div className="container hero-container">
          <div className="hero-column is-relative hero-column--left has-one-row">
            <motion.div
              variants={motionVariants}
              initial="hiddenFromLeft"
              animate={"fadeFromLeft"}
              className="element-motion"
            >
              <Img
                className="hero-image-wrapper"
                name="heroAwardImage1"
                isFluid={true}
              />
            </motion.div>
            <div className="hero-text">
              <motion.div
                initial={"hidden"}
                animate={"visible"}
                variants={heroTextVariants}
                className="hero-text-animation"
              >
                <Heading className="is-heading">
                  {data.heading}
                </Heading>
              </motion.div>
            </div>
          </div>
          <div className="hero-column hero-column--right has-two-row">
            <motion.div
              variants={motionVariants}
              animate={"fadeFromRight"}
              initial="hiddenFromRight"
              className="element-motion"
            >
              <Img
                className="hero-image-wrapper"
                name="heroAwardImage2"
                isFluid={true}
              />
            </motion.div>
            <motion.div
              variants={motionVariants}
              initial="hiddenFromRight"
              animate={"fadeFromRight"}
              className="element-motion"
            >
              <Img
                className="hero-image-wrapper"
                name="heroAwardImage3"
                isFluid={true}
              />
            </motion.div>
          </div>
        </motion.div>
      </div>
      <div className="max-container wrapper--award--block-1">
        <div className="container max-1048">
          <div className="col-images">
            <Swiper {...params}>
              {data.contentSlot1.slideshow.map(({image, title}, index) => {
                return (
                <div key={`elle-slide-image-${index}`} className="each-image">
                  <div className="swiper-caption-item">
                    <Photo
                      className="swiper-caption--image"
                      data={image}
                      alt="Elle competition slide"
                    />
                    <div className="swiper-caption--caption">{title}</div>
                  </div>
                </div>
              )
              })}
            </Swiper>
          </div>
          <div className="col-content">
            <p>
              {data.contentSlot1.text}
            </p>
          </div>
        </div>
      </div>
      <div className="max-container wrapper--award--block-2">
        <VideoWrapper videoId={data.contentSlot1.youtubeId} />
      </div>
      <div className="max-container wrapper--award--block-3">
        <div className="container max-1048 is-reverse">
          <div className="col-images">
            <Swiper {...params}>
              {data.contentSlot2.slideshow.map(({image, title}, index) => (
                <div key={`telstra-awards-slide-image-${index}`} className="each-image">
                  <div className="swiper-caption-item">
                    <Photo
                      className="swiper-caption--image"
                      data={image}
                      alt="Telstra Awards"
                    />
                    <div className="swiper-caption--caption">{title}</div>
                  </div>
                </div>
              ))}
            </Swiper>
          </div>
          <div className="col-content">
            <p>
              {data.contentSlot2.text}
            </p>
          </div>
        </div>
      </div>
      <div className="max-container wrapper--award--block-4">
        <VideoWrapper videoId={data.contentSlot2.youtubeId} />
      </div>
    </React.Fragment>
  )
}

export default ViewAward
